// TODO: use centralized component between here and activities-desking
import React from 'react';

// components
import { Grid } from '@interstate-104/components/Grid';

import LineItemWithChildren from '../common/LineItemWithChildren';

// constants
import * as constants from '../../constants';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// libraries
import { useDealRootState } from '../../offerCompareColumn/hooks/useDealRootState';
import type { DealHistory } from '@makemydeal/dr-dash-types';
import { offerReduxSelectors, offerSelectors } from '@makemydeal/dr-dash-store';
import DealSummaryItem from '../common/DealSummaryItem';

export type MonthlyPaymentProps = {
    deal?: DealHistory;
    useWrapper?: boolean;
    hasDelta?: boolean;
};

const MonthlyPayment = ({ deal, useWrapper = true, hasDelta = false }: MonthlyPaymentProps) => {
    const state = useDealRootState(deal);
    // monthly payment
    const monthlyPayment = offerSelectors.getMonthlyPayment(state as any);
    // base monthly payment
    const baseMonthlyPayment = offerReduxSelectors.getBaseMonthlyPayment(state);
    // depreciation
    const depreciation = offerReduxSelectors.getTotalDepreciation(state);
    // rent charged
    const rentCharge = offerReduxSelectors.getMonthlyRentCharged(state);
    // monthly use tax
    const monthlyUseTax = offerReduxSelectors.getMonthlyUseTax(state);
    // monthly payments total
    const totalBasePayments = offerReduxSelectors.getBaseMonthlyPaymentTotal(state);
    // total interest
    const totalInterest = offerReduxSelectors.getTotalInterest(state);

    return (
        <DealSummaryItem label={constants.MONTHLY_PAYMENT} value={formatDollarsAndCents(monthlyPayment)} hasDelta={hasDelta}>
            <Grid>
                <LineItemWithChildren label={constants.BASE_MO_PAYMENT} value={formatDollarsAndCents(baseMonthlyPayment)} />
                <LineItemWithChildren label={constants.TOTAL_BASE_MO_PAYMENTS} value={formatDollarsAndCents(totalBasePayments)} />
                <LineItemWithChildren label={constants.DEPRECIATION} value={formatDollarsAndCents(depreciation)} />
                <LineItemWithChildren label={constants.RENT_CHARGED} value={formatDollarsAndCents(rentCharge)} />
                <LineItemWithChildren label={constants.TOTAL_INTEREST} value={formatDollarsAndCents(totalInterest)} />
                <LineItemWithChildren label={constants.MO_USE_TAX} value={formatDollarsAndCents(monthlyUseTax)} />
            </Grid>
        </DealSummaryItem>
    );
};

export default MonthlyPayment;
