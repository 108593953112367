// externals
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import React from 'react';

// constants
import { RouteValues } from '@makemydeal/dr-dash-store';

//components
import { Button } from '@interstate/components/Button';
import { Box } from '@interstate/components/Box';
import { Grid } from '@interstate-104/components/Grid';
import { Typography } from '@interstate-104/components/Typography';

export const OfferHistoryCard: React.FC = () => {
    const dispatch = useDispatch();
    const onOfferHistoryClick = () => {
        dispatch(push(RouteValues.DEAL_HISTORY));
    };
    return (
        <Grid
            container
            data-testid="offer-history-card"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexGrow={1}
            textAlign="center"
            minWidth="300px"
        >
            <Typography
                id="offer-history-section-title"
                data-testid="offer-history-section-title"
                variant="body-sm"
                tag="span"
                sx={{
                    paddingTop: '16px'
                }}
            >
                View deal history and compare previous offers
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Button
                    sx={{
                        marginTop: '1rem',
                        flexGrow: 0
                    }}
                    size="small"
                    buttonStyle="secondary"
                    data-testid="btn-offer-history"
                    onClick={onOfferHistoryClick}
                >
                    Offer History
                </Button>
            </Box>
        </Grid>
    );
};

export default OfferHistoryCard;
