// istanbul ignore file
import { FC, useEffect, useState } from 'react';

import { Action } from '@interstate-104/components/Action';
import { QuestionMarkCircleIcon } from '@interstate/components/Icons';
import { Tooltip } from '@interstate/components/Tooltip';

import { ActionWrapper } from './WalkMeHelper.style';

interface WalkMeHelperProps {
    scriptUrl: string;
}

const WalkMeHelper: FC<WalkMeHelperProps> = ({ scriptUrl }) => {
    const [isWalkMeLoaded, setWalkMeLoaded] = useState<boolean>(false);

    useEffect(() => {
        const scriptWalkMe = document.createElement('script');
        scriptWalkMe.src = scriptUrl;
        scriptWalkMe.type = 'text/javascript';
        scriptWalkMe.async = true;
        scriptWalkMe.id = 'walkMe';
        document.body.appendChild(scriptWalkMe);
        scriptWalkMe.onload = () => setWalkMeLoaded(true);
        return () => {
            scriptWalkMe.remove();
        };
    }, [scriptUrl]);

    return (
        <ActionWrapper>
            {isWalkMeLoaded && (
                <Tooltip
                    data-testid="help-me-tooltip"
                    className="label-tooltip"
                    size="large"
                    toolTipContent="Your real-time guided help tool and place to learn more."
                    position="bottom"
                >
                    <Action
                        size="md"
                        data-testid="walkMe-help-button"
                        sx={{
                            '.ids-action': {
                                gap: '4px'
                            }
                        }}
                        actionIcon={{
                            icon: <QuestionMarkCircleIcon />,
                            start: true
                        }}
                        aria-label="Help"
                    >
                        Help
                    </Action>
                </Tooltip>
            )}
        </ActionWrapper>
    );
};

export default WalkMeHelper;
