import { TrashIcon } from '@interstate/components/Icons/TrashIcon';
import { UserIcon } from '@interstate/components/Icons/UserIcon';
import { UserPlusIcon } from '@interstate/components/Icons/UserPlusIcon';
import { Typography } from '@interstate-104/components/Typography';
import { offerReduxActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { CommonConsumer } from '@makemydeal/dr-platform-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCustomerUI } from '../customerUI/useCustomerUI';
import { ShowWithFeatureToggle } from '../utils/ShowWithFeatureToggle';
import { AnchorFlexRowCentered } from './CoBuyerLink.styles';
import { CoBuyerRemoveModal } from './CoBuyerRemoveModal';

export const CoBuyerLink = () => {
    const { showSearch, showCustomerCard } = useCustomerUI();
    const [showRemoveModal, setShowRemoveModal] = useState(false);

    const dispatch = useDispatch();
    const coBuyerExists = useSelector(offerReduxSelectors.hasCoBuyer);
    const coBuyerCommonConsumerId = useSelector(offerReduxSelectors.getCoBuyerCommonConsumerId);
    const coBuyerName = useSelector(offerReduxSelectors.getCoBuyerFullName);

    const checkSelectionAndUpdate = (customer: CommonConsumer): boolean => {
        dispatch(offerReduxActionCreators.updateCoBuyer(customer));
        return true;
    };

    const setCoBuyerAndCloseSeach = (customer: CommonConsumer) => {
        if (checkSelectionAndUpdate(customer)) showSearch(false);
    };
    const handleRemoveCoBuyer = () => {
        setShowRemoveModal(true);
    };

    const handleAddCoBuyer = () => {
        showSearch(true, setCoBuyerAndCloseSeach);
    };

    const handleViewCoBuyer = () => {
        showCustomerCard(true, {
            commonConsumerId: coBuyerCommonConsumerId,
            customerEditedCallback: checkSelectionAndUpdate,
            readOnlyFields: false
        });
    };

    return (
        <ShowWithFeatureToggle name="enableCoBuyer">
            <Typography
                variant="body-md"
                tag="span"
                color={coBuyerExists ? undefined : 'base.color.blue.700'}
                sx={{
                    fontWeight: 300,
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '.5rem',
                    alignItems: 'center',
                    whiteSpace: 'nowrap'
                }}
            >
                {coBuyerExists && (
                    <>
                        Co-Buyer: {coBuyerName}
                        <Typography
                            variant="body-md"
                            tag="span"
                            color="base.color.blue.700"
                            sx={{ display: 'flex', flexDirection: 'row', gap: '.5rem', alignItems: 'center' }}
                        >
                            <AnchorFlexRowCentered data-testid="view-cobuyer-link" onClick={handleViewCoBuyer}>
                                <UserIcon color="base.color.blue.700" />
                            </AnchorFlexRowCentered>
                            <AnchorFlexRowCentered data-testid="remove-cobuyer-link" onClick={handleRemoveCoBuyer}>
                                <TrashIcon color="base.color.blue.700" />
                            </AnchorFlexRowCentered>
                        </Typography>
                    </>
                )}
                {!coBuyerExists && (
                    <AnchorFlexRowCentered data-testid="add-cobuyer-link" onClick={handleAddCoBuyer}>
                        <UserPlusIcon />
                        Add Co-Buyer
                    </AnchorFlexRowCentered>
                )}
            </Typography>
            <CoBuyerRemoveModal show={showRemoveModal} setShowModal={setShowRemoveModal} />
        </ShowWithFeatureToggle>
    );
};
