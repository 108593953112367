// external libs
import React from 'react';

// components
import { Grid } from '@interstate-104/components/Grid';

import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// libraries
import type { DealHistory } from '@makemydeal/dr-dash-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import {
    offerSelectors,
    offerReduxSelectors,
    compositeSelectors,
    menuShopperActivity,
    accessoriesSelectors,
    vehicleProtectionSelectors
} from '@makemydeal/dr-dash-store';

// types
import type { LineItem } from '../../types/LineItemTypes';

// constants
import * as constants from '../../constants';

// utils
import {
    convertAccessoryToLineItem,
    getCappedFeeBreakdown,
    convertDealerFeeToLineItem,
    convertGovernmentFeeToLineItem,
    convertMenuToLineItem,
    convertVppProductToLineItem
} from '../utils/transform';

// components
import { DealSummaryItemWrapper } from '../common/DealSummaryItemWrapper';
import LineItemWithChildren from '../common/LineItemWithChildren';

// hooks
import { useDealRootState } from '../../offerCompareColumn/hooks/useDealRootState';

export type AddsToCapCostProps = {
    deal?: DealHistory;
    useWrapper?: boolean;
    hasDelta?: boolean;
};

const AddsToCapCost = ({ deal, useWrapper = true, hasDelta = false }: AddsToCapCostProps) => {
    const state = useDealRootState(deal);

    const addsToCapCostTotal = compositeSelectors.getAddsToCapCostTotal(state);

    const accessoriesTotal = accessoriesSelectors.getCapAccessoriesTotal(state);
    const itemizedAccessories = accessoriesSelectors.getCapAccessoriesList(state).map(convertAccessoryToLineItem);

    const dealerFeeTotal = offerSelectors.getCappedDealerFeesTotal(state);

    const itemizedDealerFees = offerSelectors.getDealerFees(state);
    const { cappedFees } = getCappedFeeBreakdown(itemizedDealerFees);
    const dealerFeeChildren = cappedFees.map(convertDealerFeeToLineItem);

    const govermentFee = offerReduxSelectors.getGovernmentFees(state);
    const { cappedFeeTotal, fee } = govermentFee;

    const govtFeeCapped = fee.taxFee.filter((fee) => fee.capped && fee.amount > 0);
    const govFeeChildren: LineItem[] = govtFeeCapped.map(convertGovernmentFeeToLineItem);

    const isFeatureToggleLoaded = featureToggleSelectors.areFeatureTogglesAvailable(state);
    const useVehicleProtectionActivity = isFeatureToggleLoaded
        ? vehicleProtectionSelectors.isVehicleProtectionActivityEnabled(state)
        : false;

    const protectionProductsTotal = useVehicleProtectionActivity
        ? vehicleProtectionSelectors.getCapVppTotal(state)
        : menuShopperActivity.menuSelectors.getListTotalPrice(state);
    const protectionProductsItems: LineItem[] = useVehicleProtectionActivity
        ? vehicleProtectionSelectors.getSelectedCapVppProducts(state).map(convertVppProductToLineItem)
        : menuShopperActivity.menuSelectors.getSelectedProtectionList(state).map(convertMenuToLineItem);

    return (
        <DealSummaryItemWrapper
            label={constants.ADDS_TO_CAP_COST}
            value={formatDollarsAndCents(addsToCapCostTotal)}
            useWrapper={useWrapper}
            hasDelta={hasDelta}
        >
            <Grid>
                <LineItemWithChildren
                    label={constants.ACCESSORIES}
                    value={formatDollarsAndCents(accessoriesTotal)}
                    childItems={itemizedAccessories}
                />
                <LineItemWithChildren
                    label={constants.DEALER}
                    value={formatDollarsAndCents(dealerFeeTotal)}
                    childItems={dealerFeeChildren}
                />
                <LineItemWithChildren
                    label={constants.GOV_FEES}
                    value={formatDollarsAndCents(cappedFeeTotal)}
                    childItems={govFeeChildren}
                />
                <LineItemWithChildren
                    label={constants.PROTECTION}
                    value={formatDollarsAndCents(protectionProductsTotal)}
                    childItems={protectionProductsItems}
                />
            </Grid>
        </DealSummaryItemWrapper>
    );
};

export default AddsToCapCost;
