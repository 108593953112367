// externals
import { useSelector } from 'react-redux';

// libraries
import { Grid } from '@interstate-104/components/Grid';
import { Typography } from '@interstate-104/components/Typography';
import {
    DealActivityInterstate,
    DealComparisonInterstate,
    DealDatesAndStatus,
    DealPulseInterstate,
    ShowWithSelector,
    Terms
} from '@makemydeal/dr-dash-components';

// components
import { FeesCard } from './fees/FeesCard';
import { default as DeskingActionsInterstate } from './DeskingActions.interstate';
import { DealDashboardContainer, DealWrapper, HeaderRowWrapper, InterstateDealActivityContainer } from './DeskingActivity.styles';
import { DeskingAlerts } from './DeskingAlerts';
import OfferCommentsInterstate from './OfferComments.interstate';
import InterstateAccessoriesCard from './accessories/AccessoriesCard.interstate';
import InterstateDealSummary from './dealSummary/DealSummary.interstate';
import { RebatesIndex } from './rebates/index';
import InterstateTradeFormCard from './tradeForm/TradeFormCard.interstate';
import TaxCard from './taxes/Taxes/TaxCard';

import { VehicleProtectionPreview } from './vehicleProtection/VehicleProtectionPreview';

// selectors
import { dealLifecycleSelectors, vehicleProtectionSelectors } from '@makemydeal/dr-dash-store';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

// utils
import { useInterstateToken } from '@interstate-104/components/InterstateThemeProvider';
import { VehicleProtectionSummary } from './VehicleProtectionSummary';
import { LenderChangeAlert } from './LenderChangeAlert';

const DeskingActivityInterstate = () => {
    const dealPulseAdminToggle = useSelector(dealerSelectors.getDealPulseToggle);

    const deskingActivityBackgroundColor = useInterstateToken()('base.color.gray.50');

    const dealLifecycle = useSelector(dealLifecycleSelectors.getDealLifecycle);
    const isDealLifeCycleEnabled = useSelector(featureToggleSelectors.isDealLifeCycleEnabled);

    return (
        <Grid container data-testid="activities-desking" spacing={2}>
            <InterstateDealActivityContainer
                bgcolor={deskingActivityBackgroundColor}
                container
                flexDirection="column"
                data-testid="deal-activity-container"
                xs={3}
                gap="8px"
            >
                {/* TODO: US1183743 - Use interstate typography for this h3 */}
                <h3
                    style={{
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '120%',
                        textTransform: 'capitalize'
                    }}
                >
                    Deal Progress
                </h3>
                <DealActivityInterstate className="hidden-xs hidden-sm hidden-md" />
            </InterstateDealActivityContainer>
            <Grid xs paddingY="40px">
                <LenderChangeAlert />
                <DealDashboardContainer>
                    <Typography variant="h1">Deal Dashboard</Typography>
                    {isDealLifeCycleEnabled && dealLifecycle.status && (
                        <Grid container justifyContent="flex-end">
                            <DealDatesAndStatus />
                        </Grid>
                    )}
                </DealDashboardContainer>
                <DealComparisonInterstate />
                <Grid container columnGap={2} rowGap={2}>
                    <DealWrapper columnGap={2} width="100%" data-testid="deal-wrapper">
                        {dealPulseAdminToggle && <DealPulseInterstate />}
                        <InterstateDealSummary />
                    </DealWrapper>
                    <Grid container data-testid="main-content-container" xs flexDirection="column" flexWrap="nowrap" rowGap={2}>
                        <HeaderRowWrapper>
                            <Typography variant="h3">Current Working Offer</Typography>
                        </HeaderRowWrapper>
                        <DeskingAlerts />
                        <Terms />
                        <InterstateTradeFormCard />
                        <TaxCard />
                        <FeesCard />
                        {/* NOTE: this duplication of RebatesIndex is purely cosmetic. */}
                        <RebatesIndex />
                        <ShowWithSelector
                            selector={vehicleProtectionSelectors.isVehicleProtectionActivityEnabled}
                            active={<VehicleProtectionPreview />}
                            inactive={<VehicleProtectionSummary />}
                        />
                        <Grid container flexDirection="row" rowGap={2} spacing={2}>
                            <Grid xs={12} sm={12} lg={12}>
                                <InterstateAccessoriesCard />
                            </Grid>
                        </Grid>
                        <OfferCommentsInterstate />
                        <DeskingActionsInterstate />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DeskingActivityInterstate;
