import { Action } from '@interstate-104/components/Action';
import { Box } from '@interstate/components/Box';
import { Typography } from '@interstate/components/Typography';
import { kebabCase } from 'lodash';

export type ILocationRowProps = {
    label: string;
    location?: {
        gps?: string;
        lot?: string;
    };
};

export const LocationItemRow = ({ label, location }: ILocationRowProps) => {
    const { gps, lot } = location || {};

    return (
        <Box className="rowItem">
            <Typography variant="body-md" color="base.color.gray.600" data-testid="vehicle-drawer-label">
                {label}
            </Typography>
            <Typography data-testid={`vehicle-drawer-value-${kebabCase(label)}`} variant="body-md">
                {gps && (
                    <a
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(gps)}`}
                        target="_blank"
                        data-testid="vehicle-info-drawer-location-link"
                        rel="noreferrer"
                    >
                        <Action size="sm">{gps}</Action>
                    </a>
                )}
                {gps && lot && ', '}
                {lot && lot}
            </Typography>
        </Box>
    );
};
