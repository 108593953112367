// externals
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { ModalDialog } from '@interstate/components/ModalDialog';
import { Grid } from '@interstate-104/components/Grid';
import { Button } from '@interstate/components/Button';
import { Typography } from '@interstate/components/Typography';
import { pushToRouteOneActionCreators } from '@makemydeal/dr-dash-store';

export interface IPushToRouteOneDialogProps {
    show: boolean;
    onHide(): void;
}

const PushToRouteOneDialog = (props: IPushToRouteOneDialogProps) => {
    const { show, onHide } = props;
    const dispatch = useDispatch();

    const prefix = 'push-to-routone-dialog';

    const handleConfirmPushToRouteOne = () => {
        dispatch(pushToRouteOneActionCreators.confirmPushToRouteOne());
    };

    const header = (
        <Typography data-testid={`${prefix}-header`} variant="h4" tag="h4" color="sem.color.on-surface.primary">
            Push To RouteOne
        </Typography>
    );
    const footer = (
        <>
            <Grid container justifyContent="flex-end" data-testid={`${prefix}-footer`}>
                <Button
                    htmlId={`${prefix}-close-button`}
                    data-testid={`${prefix}-close-button`}
                    buttonStyle="secondary"
                    onClick={onHide}
                >
                    Cancel
                </Button>
            </Grid>

            <Grid container justifyContent="flex-end" data-testid={`${prefix}-footer`}>
                <Button
                    htmlId={`${prefix}-done-button`}
                    data-testid={`${prefix}-done-button`}
                    buttonStyle="primary"
                    onClick={handleConfirmPushToRouteOne}
                >
                    Confirm
                </Button>
            </Grid>
        </>
    );

    return (
        <ModalDialog size="large" htmlId={prefix} data-testid={prefix} show={show} header={header} footer={footer} onHide={onHide}>
            <Typography data-testid={`${prefix}-stock-number-input`} variant="body-sm" tag="p" color="sem.color.on-surface.primary">
                Make sure all financial and contracting activities have been completed before pushing to RouteOne
            </Typography>
        </ModalDialog>
    );
};

export default PushToRouteOneDialog;
