/* eslint-disable react-hooks/rules-of-hooks */
// externals
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import type { DealHistory } from '@makemydeal/dr-dash-types';
import { bootstrapSharedActionCreators } from '@makemydeal/dr-shared-store';
import {
    dealHistoryActionCreators,
    dealHistoryActionTypes,
    dealHistorySelectors,
    quickCompareActionCreators,
    offerInfoSelectors
} from '@makemydeal/dr-dash-store';

// components
import { Card, CardActions } from '@interstate/components/Card';
import { Tooltip } from '@interstate/components/Tooltip';
import QuickCompareWithInterstate from '../quickCompare/QuickCompare.interstate';
import { OfferMessage } from '../offerMessage/OfferMessage.interstate';
import { OfferCardHeader } from '../offerCardHeader/OfferCardHeader.interstate';
import MakeCurrentModal from '../makeCurrentModal/MakeCurrentModal.interstate';

// styles
import { OfferBadge } from './OfferCard.style.interstate';

// types
import type { IPencilOfferInputs, IShopper } from '@makemydeal/dr-platform-types';

// utils
import { dateUtils } from '@makemydeal/dr-common-utils';

import OfferCardDetailsInterstate from '../offerCardDetails/OfferCardDetails.interstate';
import { CheckBox } from '@interstate/components/CheckBox';
import { Box } from '@interstate/components/Box';
import { rem } from 'polished';
import { Grid } from '@interstate-104/components/Grid';
import { MakeCurrentButtonView } from '../makeCurrentModal/types';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

export interface IOfferCardProps {
    deal?: DealHistory;
    isDealHistory?: boolean;
    isDefaultOffer?: boolean;
    isMostRecentDealerOffer?: boolean;
    isMostRecentShopperOffer?: boolean;
}

export const DISABLED_CHECKBOX_MESSAGE = "You've reached your max comparison, uncheck one of the other cards to continue";

export const OfferCardInterstate: React.FC<IOfferCardProps> = ({
    deal,
    isDealHistory,
    isDefaultOffer,
    isMostRecentDealerOffer,
    isMostRecentShopperOffer
}: IOfferCardProps) => {
    if (!deal) {
        return null;
    }

    const selectedDeals = useSelector(dealHistorySelectors.getSelectedDealHistory);
    const dispatch = useDispatch();

    const { offer } = deal;
    const { dealXgVersion } = offer;

    const updateQuickCompareOffer = React.useCallback(() => {
        dispatch(quickCompareActionCreators.updateQuickCompareOffer(deal as DealHistory));
    }, [dispatch, deal]);

    const makeCurrentOffer = React.useCallback(() => {
        dispatch({ type: dealHistoryActionTypes.FETCH_DEAL_HISTORY_REQUEST });
        const fetchBootstrap = bootstrapSharedActionCreators.fetchBootstrapData(MANAGER_VIEW_APP_PREFIX, {
            withCurrentDealVersion: true,
            dealXgVersion
        });
        dispatch(fetchBootstrap as any);
    }, [dispatch, deal, dealXgVersion]);

    const dealCheckedChange = React.useCallback(
        (event: any) => {
            if (event.target.value.checked) {
                dispatch(dealHistoryActionCreators.dealHistoryChecked(deal));
                dispatch(dealHistoryActionCreators.dealHistorySelectedUpdate(deal.offer.offerId as string));
            } else {
                dispatch(dealHistoryActionCreators.dealHistoryUnchecked(deal.offer.offerId as string));
            }
        },
        [dispatch, deal]
    );

    const isCheckboxDisabled = () => {
        const isDealSelected = selectedDeals.find((selectedDeal) => selectedDeal.offer.offerId === deal.offer.offerId);
        return !isDealSelected && selectedDeals.length === 2;
    };

    const isCheckboxDisabledMemoed = useMemo(() => isCheckboxDisabled(), [selectedDeals]);

    const renderCheckbox = () => {
        const checkboxId = 'deal-compare-checkbox-' + deal.offer.offerId;
        return isCheckboxDisabledMemoed ? (
            <Tooltip
                htmlId="tooltipLink"
                toolTipContent={DISABLED_CHECKBOX_MESSAGE}
                data-testid="disabled-offer-card-tooltip-interstate"
                size="large"
                position="bottom"
            >
                <div>
                    <CheckBox
                        id={checkboxId}
                        name="checkBoxList"
                        onChange={dealCheckedChange}
                        value="1"
                        label={'Compare'}
                        disabledChecked
                        disabled
                    />
                </div>
            </Tooltip>
        ) : (
            <CheckBox id={checkboxId} name="checkBoxList" onChange={dealCheckedChange} value="1" label={'Compare'} />
        );
    };

    const { shopper, createdOn, offerType, terms, offerSource, penciledInputs, accepted } = deal.offer;
    const dealerMessage = deal.offer.cmdMessageToShopper;
    const messageToDealer = deal.offer.messageToDealer;
    const { firstName, lastName } = shopper as IShopper;
    const createdOnFormattedDate = createdOn && dateUtils.formatAsMMDDYYYY(new Date(createdOn));
    const createdOnFormattedTime = createdOn && dateUtils.formatAsTime(new Date(createdOn));
    const pencilOfferInputs =
        (penciledInputs && offerType && penciledInputs.byOfferType && penciledInputs.byOfferType[offerType]) ??
        ([] as IPencilOfferInputs);
    const { term, offerPrice } = pencilOfferInputs;
    const payment = term && terms.byId[term];

    const socketEventDealVersion = useSelector(offerInfoSelectors.getSocketEventDealVersion);
    const updated = dealXgVersion === socketEventDealVersion;

    return (
        <Box width="320px" minWidth="320px" data-testid="dr-dash-offer-card" className="offer-card">
            <Box paddingBottom="12px">
                <OfferCardHeader
                    shopperFirstName={firstName}
                    shopperLastName={lastName}
                    createdOnDate={createdOnFormattedDate}
                    createdOnTime={createdOnFormattedTime}
                    offerSource={offerSource}
                    isDefaultOffer={isDefaultOffer}
                    isMostRecentDealerOffer={isMostRecentDealerOffer}
                    isMostRecentShopperOffer={isMostRecentShopperOffer}
                />
            </Box>
            <Card
                className="offer-card-container"
                data-testid="offer-card-interstate"
                sx={{ position: 'relative' }}
                content={
                    <Grid gap={1} container flexDirection="column" minHeight="131px">
                        {accepted && (
                            <OfferBadge htmlId="accepted-offer-badge" variant="success">
                                Accepted
                            </OfferBadge>
                        )}
                        {!accepted && updated && (
                            <OfferBadge htmlId="updated-offer-badge" variant="success">
                                Updated
                            </OfferBadge>
                        )}
                        {offerType && (
                            <OfferCardDetailsInterstate
                                offerType={offerType}
                                monthlyPayment={payment?.monthlyPayment}
                                netCashDown={payment?.netCashDown}
                                tradeAllowance={payment?.totalAllowance}
                                {...(offerPrice && { offerPrice })}
                                months={payment?.months}
                                apr={payment?.apr}
                                sellRate={payment?.sellRate}
                            />
                        )}
                        <Box sx={{ paddingTop: `${rem(8)}` }}>
                            <OfferMessage
                                shopperMessage={messageToDealer}
                                dealerMessage={dealerMessage}
                                offerSource={offerSource}
                            />
                        </Box>
                    </Grid>
                }
                footer={
                    <CardActions>
                        {!isDealHistory && (
                            <>
                                <QuickCompareWithInterstate onCompareClick={updateQuickCompareOffer} />
                                <MakeCurrentModal
                                    onMakeCurrentOffer={makeCurrentOffer}
                                    makeCurrentButtonView={MakeCurrentButtonView.DealComparison}
                                />
                            </>
                        )}
                        {isDealHistory && (
                            <div data-testid="dr-dash-offer-card-compare" className="offer-card-compare-check-box">
                                {renderCheckbox()}
                            </div>
                        )}
                    </CardActions>
                }
            />
        </Box>
    );
};

export default OfferCardInterstate;
