// externals
import React from 'react';
import { useSelector } from 'react-redux';

// libraries
import { Grid } from '@interstate-104/components/Grid';

import { DefaultRootState } from '@makemydeal/dr-react-18-wrappers';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { compositeSelectors, offerReduxSelectors } from '@makemydeal/dr-dash-store';

// consts/enums
import { CAP_COST_REDUCTION, CASH, REBATES, TRADE_IN } from '../../constants';

// components
import { DealSummaryItemWrapper } from '../common/DealSummaryItemWrapper';
import { QuickCompareOffer } from '../../offerCompareColumn/types/quickCompareOffer';
import LineItemWithChildren from '../common/LineItemWithChildren';

export interface ICapCostReductionProps {
    offer?: QuickCompareOffer;
    useWrapper?: boolean;
}

const CapCostReduction = ({ offer, useWrapper = true }: ICapCostReductionProps) => {
    const defaultState: DefaultRootState = useSelector((state) => state);
    const state: DefaultRootState | QuickCompareOffer = offer ? { offer } : defaultState;

    const netCashDown = offerReduxSelectors.getNetCashDown(state);
    const reductionRebate = offerReduxSelectors.getCapReductionRebate(state);
    const reductionTradeIn = offerReduxSelectors.getCapReductionTradeIn(state);
    const selectedCapCostReduction = compositeSelectors.getCapCostReduction(state as any);

    return (
        <DealSummaryItemWrapper
            label={CAP_COST_REDUCTION}
            value={formatDollarsAndCents(selectedCapCostReduction)}
            useWrapper={useWrapper}
        >
            <Grid>
                <LineItemWithChildren label={CASH} value={formatDollarsAndCents(netCashDown)} />
                <LineItemWithChildren label={REBATES} value={formatDollarsAndCents(reductionRebate)} />
                <LineItemWithChildren label={TRADE_IN} value={formatDollarsAndCents(reductionTradeIn)} />
            </Grid>
        </DealSummaryItemWrapper>
    );
};

export default CapCostReduction;
