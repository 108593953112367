import { Grid } from '@interstate-104/components/Grid';
import { Typography } from '@interstate-104/components/Typography';
import type { LineItemWithChildrenProps } from '../../types/LineItemTypes';
import { getTestId } from '../utils/htmlUtils';

const LineItemWithChildren = ({ label, value, childItems, testId }: LineItemWithChildrenProps) => {
    const testIdStr = getTestId({ label, testId });
    const labelId = `${testIdStr}-label`;
    const valueId = `${testIdStr}-amount`;

    return (
        <>
            <Grid container justifyContent="space-between" data-testid={testId}>
                <Typography tag="span" variant="label-md" color="sem.color.on-surface.muted" data-testid={labelId}>
                    {label}
                </Typography>
                <Typography tag="span" variant="body-sm" color="sem.color.on-surface.default" data-testid={valueId}>
                    {value}
                </Typography>
            </Grid>
            {childItems?.map(({ label: childLabel, value: childValue }, idx) => (
                <Grid
                    container
                    justifyContent="flex-start"
                    ml="16px"
                    key={`${childLabel}-${childValue}`}
                    data-testid={`${labelId}-${idx}`}
                    flexWrap="nowrap"
                    alignItems="flex-start"
                >
                    <Typography
                        tag="span"
                        variant="label-md"
                        color="sem.color.on-surface.muted"
                        sx={{ flexGrow: '1', minWidth: '0' }}
                    >
                        {childLabel}
                    </Typography>
                    <Typography
                        tag="span"
                        variant="body-sm"
                        color="sem.color.on-surface.default"
                        sx={{ flexShrink: '0' }}
                        data-testid={`${valueId}-${idx}`}
                    >
                        {childValue}
                    </Typography>
                </Grid>
            ))}
        </>
    );
};

export default LineItemWithChildren;
