// externals

// libraries
import {
    accessoriesSelectors,
    compositeSelectors,
    offerReduxSelectors,
    offerSelectors,
    vehicleProtectionSelectors
} from '@makemydeal/dr-dash-store';
import type { DealState } from '@makemydeal/dr-dash-types';

// components
import { Grid } from '@interstate-104/components/Grid';
import { DealSummaryItemWrapper } from '../common/DealSummaryItemWrapper';
import LineItemWithChildren from '../common/LineItemWithChildren';

// constants
import * as constants from '../../constants';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// hooks
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { useDealRootState } from '../../offerCompareColumn/hooks/useDealRootState';
import { LineItem } from '../../types/LineItemTypes';
import { convertAccessoryToLineItem, convertGovernmentFeeToLineItem, convertVppProductToLineItem } from '../utils/transform';

export type TotalDueAtSigningProps = DealState & {
    useWrapper?: boolean;
    showLargeValue?: boolean;
    hasDelta?: boolean;
};

const TotalDueAtSigning = ({ deal, useWrapper = true, showLargeValue, hasDelta = false }: TotalDueAtSigningProps) => {
    const state = useDealRootState(deal);

    const totalAmountDue = offerReduxSelectors.getDueAtSigningFromDueInAdvance(state);
    const monthlyPayment = offerSelectors.getMonthlyPayment(state);

    const upfrontTaxes = offerReduxSelectors.getUpfrontTaxTotal(state);

    const secDeposit = offerSelectors.getSecurityDepositOverrideWithFallback(state);

    const acqUpfront = offerReduxSelectors.getAcqFeeUpFrontOverride(state, true);
    const acqFee = acqUpfront ? offerReduxSelectors.getAcqFeeOverride(state, true) : 0;

    const upfrontGovFeesTotal = offerReduxSelectors.getGovernmentUpfrontFeeTotal(state);

    const upfrontGovFees = compositeSelectors.getGovernmentFeesUpFront(state);
    const govFeeChildren: LineItem[] = upfrontGovFees.map(convertGovernmentFeeToLineItem);

    const upfrontDealerFees = compositeSelectors.getDealerFeesTotal(state, false);

    const capCostReductionTax = offerReduxSelectors.getCapCostReductionTax(state);
    const advanceFlatTax = compositeSelectors.getUpfrontAdvanceFlatTax(state);

    const rebateTotal = offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash(state);

    const isFeatureToggleLoaded = featureToggleSelectors.areFeatureTogglesAvailable(state);
    const useVehicleProtectionActivity = isFeatureToggleLoaded
        ? vehicleProtectionSelectors.isVehicleProtectionActivityEnabled(state)
        : false;

    const upfrontProtectionProductsTotal = useVehicleProtectionActivity ? vehicleProtectionSelectors.getUpfrontVppTotal(state) : 0;
    const upfrontProtectionProductsItems: LineItem[] = useVehicleProtectionActivity
        ? vehicleProtectionSelectors.getSelectedUpfrontVppProducts(state).map(convertVppProductToLineItem)
        : [];

    const accessoriesTotal = accessoriesSelectors.getUpfrontAccessoriesTotal(state);
    const itemizedAccessories = accessoriesSelectors.getUpfrontAccessoriesList(state).map(convertAccessoryToLineItem);

    return (
        <DealSummaryItemWrapper
            label={constants.TOTAL_DUE_AT_SIGNING}
            value={formatDollarsAndCents(totalAmountDue)}
            useWrapper={useWrapper}
            showLargeValue={showLargeValue}
            hasDelta={hasDelta}
        >
            <Grid>
                <LineItemWithChildren label={constants.MO_PAYMENT} value={formatDollarsAndCents(monthlyPayment)} />
                <LineItemWithChildren
                    label={constants.GOV_FEES}
                    value={formatDollarsAndCents(upfrontGovFeesTotal)}
                    childItems={govFeeChildren}
                />
                <LineItemWithChildren label={constants.DOC_FEES} value={formatDollarsAndCents(upfrontDealerFees)} />
                <LineItemWithChildren label={constants.ACQUISITION_FEE} value={formatDollarsAndCents(acqFee)} />
                <LineItemWithChildren
                    label={constants.ACCESSORIES}
                    value={formatDollarsAndCents(accessoriesTotal)}
                    childItems={itemizedAccessories}
                />
                {useVehicleProtectionActivity && (
                    <LineItemWithChildren
                        label={constants.PROTECTION}
                        value={formatDollarsAndCents(upfrontProtectionProductsTotal)}
                        childItems={upfrontProtectionProductsItems}
                    />
                )}
                <LineItemWithChildren label={constants.SECURITY_DEPOSIT} value={formatDollarsAndCents(secDeposit)} />
                {/* From product: Only show CCRT if it has a value; most dealers don't pay CCRT */}
                <LineItemWithChildren label={constants.UPFRONT_TAXES} value={formatDollarsAndCents(upfrontTaxes)} />
                <LineItemWithChildren label={constants.CAP_COST_REDUCTION_TAX} value={formatDollarsAndCents(capCostReductionTax)} />
                <LineItemWithChildren label={constants.ADVANCE_FLAT_TAX} value={formatDollarsAndCents(advanceFlatTax)} />
                <LineItemWithChildren label={constants.REBATES} value={formatDollarsAndCents(rebateTotal)} />
            </Grid>
        </DealSummaryItemWrapper>
    );
};

export default TotalDueAtSigning;
