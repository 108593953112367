import { Action } from '@interstate-104/components/Action';
import { Button } from '@interstate/components/Button';
import { Bars3Icon, ChartPieIcon } from '@interstate/components/Icons';
import { Popover } from '@interstate/components/Popover';
import { configSelectors, routingSelectors } from '@makemydeal/dr-dash-store';
import { CoxBridgeBar, RenderWhenOktaEnabled } from '@makemydeal/dr-shared-components';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { FC, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useResizeObserver } from '../common/useResizeObserver';
import HeaderVehicleImage from '../dealActivity/HeaderVehicleImage.interstate';
import { NavDrawerContext } from '../navDrawer';
import BackToMyDealButton from './BackButton.interstate';
import DealInfoInterstate from './DealInfo.interstate';
import {
    BackButtonRow,
    BridgeBarContainer,
    DealInfoContainer,
    HamburgerMenuContainer,
    HeaderContainer,
    HeaderContent,
    HeaderContentRow,
    HeaderRoot,
    HeaderRow,
    ModalWrapperContainer,
    TopBarContainer
} from './Header.style.interstate';
import { useHeaderMeasure } from './HeaderMeasureProvider';
import ProfitInfoInterstate from './ProfitInfo.intertstate';
import SummaryInterstate from './Summary.interstate';
import WalkMeHelper from './WalkMeHelper';

export type HeaderInterstateProps = {
    appPrefix: string;
};

export const HeaderInterstate: FC<HeaderInterstateProps> = (props) => {
    const [sliderHeight, setSliderHeight] = useState<number>(0);
    const dashboardIsCurrentRoute = useSelector(routingSelectors.isDashboardCurrentRoute);
    const showBackButton = !dashboardIsCurrentRoute;
    const refRoot = useRef<HTMLDivElement | null>(null);
    const refSlider = useRef<HTMLDivElement | null>(null);
    const { setHeight } = useHeaderMeasure();
    const { toggleDrawer } = useContext(NavDrawerContext);
    const hamburgerMenuBreakpoint = useMediaQuery({ query: '(max-width: 1360px)' });
    const walkMeUrl = useSelector(configSelectors.getWalkMeUrl);
    const isWalkMeMvEnabled = useSelector(featureToggleSelectors.isWalkMeMvEnabled);

    useResizeObserver(refRoot, (entry) => {
        const totalHeaderHeight = entry.borderBoxSize[0].blockSize;
        setHeight(totalHeaderHeight - sliderHeight);
    });

    useResizeObserver(refSlider, (entry) => {
        setSliderHeight(entry.borderBoxSize[0].blockSize);
    });

    return (
        <HeaderRoot data-testid="header-root" ref={refRoot}>
            <HeaderContainer>
                <TopBarContainer>
                    {hamburgerMenuBreakpoint && (
                        <HamburgerMenuContainer>
                            <Button
                                data-testid="DealNavigationBarToggle"
                                htmlId="DealNavigationBarToggle"
                                buttonStyle="tertiary"
                                onClick={toggleDrawer}
                                icon={<Bars3Icon />}
                            />
                        </HamburgerMenuContainer>
                    )}
                    <BridgeBarContainer>
                        <RenderWhenOktaEnabled>
                            <CoxBridgeBar appPrefix={props.appPrefix} />
                        </RenderWhenOktaEnabled>
                    </BridgeBarContainer>
                </TopBarContainer>

                <HeaderContent>
                    {showBackButton && (
                        <BackButtonRow>
                            <BackToMyDealButton />
                        </BackButtonRow>
                    )}
                    <HeaderRow>
                        <HeaderVehicleImage />
                        <HeaderContentRow>
                            <SummaryInterstate />
                            <DealInfoContainer>
                                <DealInfoInterstate />
                                <ModalWrapperContainer>
                                    {walkMeUrl && isWalkMeMvEnabled && (
                                        <WalkMeHelper scriptUrl={walkMeUrl} data-testid="walkMe-wrapper" />
                                    )}
                                    <Popover
                                        padding={true}
                                        header="Profit Details"
                                        data-testid="profit-details-popover"
                                        position="bottom-end"
                                        trigger="outsideClick"
                                        popoverContent={<ProfitInfoInterstate />}
                                    >
                                        <Action
                                            size="md"
                                            data-testid="profit-details-btn"
                                            sx={{
                                                '.ids-action': {
                                                    gap: '4px'
                                                }
                                            }}
                                            actionIcon={{
                                                icon: <ChartPieIcon />,
                                                start: true
                                            }}
                                        >
                                            Profit
                                        </Action>
                                    </Popover>
                                </ModalWrapperContainer>
                            </DealInfoContainer>
                        </HeaderContentRow>
                    </HeaderRow>
                </HeaderContent>
            </HeaderContainer>
        </HeaderRoot>
    );
};

export default HeaderInterstate;
