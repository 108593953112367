import { Box } from '@interstate/components/Box';
import { Typography } from '@interstate-104/components/Typography';

export type VehicleNotesProps = {
    notes: string;
};

export const VehicleNotes: React.FC<VehicleNotesProps> = ({ notes }) => {
    return notes ? (
        <Box>
            <Typography variant="h5" data-testid="vehicle-drawer-vehicle-notes-heading">
                Vehicle Notes
            </Typography>
            <Box marginTop={2}>
                <Typography variant="body-sm" data-testid="vehicle-drawer-vehicle-notes-body">
                    {notes}
                </Typography>
            </Box>
        </Box>
    ) : null;
};
