import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { isEqual } from 'lodash';

import { ToggleGroup } from '@interstate/components/ToggleGroup/ToggleGroup/ToggleGroup';
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';
import {
    offerReduxSelectors,
    offerReduxActionCreators,
    deskingSelectors,
    offerActionTypes,
    offerSelectors
} from '@makemydeal/dr-dash-store';
import { RESIDUAL_PERCENT, ResidualValueType, RESIDUAL_DOLLAR, LEASE } from '@makemydeal/dr-platform-types';
import { DEBOUNCE_TIMER, GENERIC_LAST_FIELD_CHANGED_ERROR, BASE_RESIDUAL } from '../../constants';
import { convertToNumber, formatAprRate } from '../../utils/formatUtils';
import { useFeatureToggle } from '../../utils/useFeatureToggle';
import {
    StyledTextWithToggleGroupText,
    StyledTextWithToggleGroupToggle,
    StyledTextWithToggleGroupContainer
} from './StyledTextWithToggleGroup.styles';
import { NumericInput } from '@interstate/components/NumericInput';

export const BaseResidualField = () => {
    const dispatch = useDispatch();
    const enabled = useFeatureToggle('enableAppliedLenderInfo');

    // credit decision toggled on
    const isUserProgramQuotes = useSelector(offerReduxSelectors.getIsUserProgramQuotes);

    const disableControl = (enabled && !isUserProgramQuotes) || !enabled;

    const monthsSelector = (state: any) => {
        const term = offerReduxSelectors.getSelectedTermByOfferType(state, LEASE);
        return term?.months;
    };
    const months = useSelector(monthsSelector);

    // Grab current values from the redux store based on offerType and months
    const { baseResidualType, baseResidualAmount, baseResidualPercent } = useSelector(
        offerSelectors.getResidualInfoOverrideWithFallback,
        isEqual
    );

    const [unitType, setUnitType] = useState<ResidualValueType>(baseResidualType);

    const textFormatter = useCallback(
        (v: number): string => {
            if (unitType === RESIDUAL_PERCENT) return formatAprRate(v);
            return formatDollarsAndCents(v);
        },
        [unitType]
    );

    const selectedValue = useMemo(() => {
        let value = baseResidualAmount;
        if (unitType === RESIDUAL_PERCENT) value = baseResidualPercent;
        return value;
    }, [unitType, baseResidualAmount, baseResidualPercent]);

    const [text, setText] = useState(textFormatter(selectedValue));

    // NOTE: due to the debouncing, etc this function isn't getting coverage credit, so ignore...
    /* istanbul ignore next */
    function actionCaller(value: number, months: number, unitType: ResidualValueType, makePaymentCall: boolean = true) {
        // we're only going to dispatch one action when the value itself ischanged. But the type will persist alongside the value.
        dispatch(offerReduxActionCreators.updateBaseResidualOverride(value, months, unitType, makePaymentCall));
    }

    // NOTE: we're not using useDebounce because it is memoized on construction; we need to dememoize it here for access to current values in state.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedActionCaller = useCallback(debounce(actionCaller, DEBOUNCE_TIMER), []);

    // change the unit type, but don't trigger payment call.
    function handleChangeUnitType(newType: ResidualValueType) {
        setUnitType(newType);
    }

    const handleTextChanged = useCallback(
        (e: any) => {
            setText(e.target.value);
            const value = convertToNumber(e.target.value);
            debouncedActionCaller(value, months, unitType); // delayed with payment call
        },
        [months, unitType]
    );

    const failedPercent = useSelector(deskingSelectors.wasTheLastFailure(offerActionTypes.UPDATED_BASE_RESIDUAL_PERCENT_OVERRIDE));
    const failedDollar = useSelector(deskingSelectors.wasTheLastFailure(offerActionTypes.UPDATED_BASE_RESIDUAL_DOLLAR_OVERRIDE));
    const failedMessage = useMemo(
        () => (failedPercent || failedDollar ? GENERIC_LAST_FIELD_CHANGED_ERROR : undefined),
        [failedPercent, failedDollar]
    );

    // NOTE: This function IS being tested, but it's not getting coverage credit, so ignore...
    /* istanbul ignore next */
    const handleBlur = () => {
        const value = convertToNumber(text);
        if (!isNaN(value)) setText(textFormatter(value));
    };

    // This effect will keep payment responses and term switching in sync with this component's offline input state.
    useEffect(() => {
        // istanbul ignore next
        if (unitType !== baseResidualType) setUnitType(baseResidualType);
        if (baseResidualType === RESIDUAL_DOLLAR) setText(textFormatter(baseResidualAmount));
        else setText(textFormatter(baseResidualPercent));
    }, [baseResidualAmount, baseResidualPercent, baseResidualType]);

    // toggle value when unit type changes
    useEffect(() => {
        setText(textFormatter(selectedValue));
        if (unitType !== baseResidualType) actionCaller(selectedValue, months, unitType, false); // immediate; without payment call
    }, [unitType]);

    return (
        <StyledTextWithToggleGroupContainer>
            <StyledTextWithToggleGroupText>
                <NumericInput
                    error={failedMessage}
                    id="baseResidualValue"
                    data-testid="baseResidualValue"
                    label={BASE_RESIDUAL}
                    name={kebabCase(BASE_RESIDUAL)}
                    onChange={handleTextChanged}
                    onBlur={handleBlur}
                    disabled={disableControl}
                    value={text}
                    placeholder="20.00" // TODO: update placeholder based on type
                    allowDecimal={true}
                    decimalMaxLength={3}
                    decimalMinLength={2}
                    inputPrefix={'$'}
                    autoInsertCommas={true}
                />
            </StyledTextWithToggleGroupText>
            <StyledTextWithToggleGroupToggle>
                <ToggleGroup
                    size="medium"
                    id="baseResidualToggleGroup"
                    data-testid="baseResidualToggleGroupId"
                    toggleButtonOptions={[
                        {
                            children: <span>$</span>,
                            value: '$',
                            selected: unitType === RESIDUAL_DOLLAR,
                            id: 'dollarToggle',
                            'data-testid': 'baseResidualDollarTypeToggle',
                            onClick: (e: any) => {
                                handleChangeUnitType(RESIDUAL_DOLLAR);
                            }
                        },
                        {
                            children: <span>%</span>,
                            value: '%',
                            selected: unitType === RESIDUAL_PERCENT,
                            id: 'percentToggle',
                            'data-testid': 'baseResidualPercentTypeToggle',
                            onClick: (e: any) => {
                                handleChangeUnitType(RESIDUAL_PERCENT);
                            }
                        }
                    ]}
                    toggleWidth="60px"
                />
            </StyledTextWithToggleGroupToggle>
        </StyledTextWithToggleGroupContainer>
    );
};

export default BaseResidualField;
