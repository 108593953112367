// externals
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BreakPoint } from '@makemydeal/dr-activities-common';

// libraries
import { dealerSelectors, featureToggleSelectors, selectedOfferSelectors } from '@makemydeal/dr-shared-store';
import {
    deskingActionCreators,
    offerInfoSelectors,
    configSelectors,
    dealXgDetailsSelectors,
    appSelectors,
    offerActionCreators,
    printPdfActionCreator
} from '@makemydeal/dr-dash-store';

import {
    ShareToCustomerDialogInterstate,
    ShareToSalesViewDialog,
    UpdateOfferDialogInterstate,
    useCustomerUI
} from '@makemydeal/dr-dash-components';

// components
import { Button } from '@interstate-104/components/Button';
import { Menu, MenuReferenceType } from '@interstate-104/components/Menu';
import { Snackbar } from '@interstate-104/components/Snackbar';

import ReadyToSendDialogInterstate from './readyToSendDialog/ReadyToSendDialog.interstate';

// styling
import { StyledDeskingActions } from './DeskingActions.interstate.style';
import { Action } from '../constants';
import { ChevronDownIcon } from '@interstate-104/components/Icons/ChevronDownIcon';
import { useMediaQuery } from 'react-responsive';

const actionCreatorMap = {
    [Action.CONTINUE_IN_DESKING]: deskingActionCreators.continueInDesking,
    [Action.SEND_TO_ECOMMERCE]: deskingActionCreators.sendToEcommerce
} as const;

const readyToSendDialogContentMap = {
    [Action.CONTINUE_IN_DESKING]: {
        dialogHeader: 'Ready to Continue in Desking',
        dialogPrompt: 'Clicking "Yes" will update the offer and open Connect Desking. Are you sure you want to continue?'
    },
    [Action.SEND_TO_ECOMMERCE]: {
        dialogHeader: 'Ready to Send to Ecommerce?',
        dialogPrompt: 'Clicking "Yes" will send you to Ecommerce. Are you sure you want to continue?'
    }
} as const;

enum ShareActionTypes {
    SHARE_TO_SHOW_ROOM = 'ShareToShowroom',
    GET_DEAL_LINK = 'GetDealLink',
    SHARE_TO_CUSTOMER = 'ShareToCustomer',
    UPDATE = 'UPDATE',
    PRINT = 'PRINT'
}

const DeskingActionsInterstate = () => {
    const [showReadyToSendDialog, setShowReadyToSendDialog] = useState(false);
    const [showReadyToSendDialogSave, setShowReadyToSendDialogSave] = useState(false);
    const [successNotification, setSuccessNotification] = useState(false);
    const [currentAction, setCurrentAction] = useState<Action>(Action.CONTINUE_IN_DESKING);
    const actionsEnabled = useSelector(appSelectors.isLazyInitCompleteForSmartInit);
    const dealExchangeDealId = useSelector(selectedOfferSelectors.getSelectedOfferDealXgId);
    const initDealExchangeDealVersion = useSelector(selectedOfferSelectors.getSelectedOfferDealXgVersion);
    const latestDealExchangeVersion = useSelector(offerInfoSelectors.getDealXgVersion);
    const copyToDeskingURL = useSelector(configSelectors.getCopyToDeskingURL);
    const isEcommerceDeal = useSelector(dealXgDetailsSelectors.getIsEcommerceDeal);
    const redirectionLink = useSelector(dealXgDetailsSelectors.getRedirectionLink);
    const offerIsBeingSaved = useSelector(offerInfoSelectors.getIsOfferBeingSaved);
    const offerHasBeenSaved = useSelector(offerInfoSelectors.getHasBeenSaved);
    const [showShareToSalesViewDialog, setShowShareToSalesViewDialog] = useState(false);
    const [showShareToCustomerDialog, setShowShareToCustomerDialog] = useState(false);
    const [showGetDealLinkDialog, setShowGetDealLinkDialog] = useState(false);
    const [showUpdateOffer, setShowUpdateOffer] = useState(false);
    const [showShareDropdown, setShareDropdown] = useState<null | HTMLElement>(null);
    const openShareDropdown = Boolean(showShareDropdown);
    const crmIntegrationToggle = useSelector(dealerSelectors.getCrmIntegrationToggle);
    const { showCustomerCard } = useCustomerUI();
    const largerThanSM = useMediaQuery({ query: `(min-width: ${BreakPoint.SMALL})` });
    const isContinueToDeskingDisabled = useSelector(dealerSelectors.isContinueToDeskingDisabled);
    const isLatestPushToDmsPlusEnabled = useSelector(dealXgDetailsSelectors.getLatestPushToDmsPlus);
    const isPdfPrintMvEnabled = useSelector(featureToggleSelectors.isPdfPrintMvEnabled);

    // istanbul ignore next # this is invoked WAY down the pipeline and out of scope here.
    const urlSelector = (state: any) => {
        const url = offerInfoSelectors.getShopperURL(state);
        return `\n${url}`;
    };
    const dispatch = useDispatch();

    const dispatchAction = (action: Action) => {
        const actionCreator = actionCreatorMap[action];
        if (actionCreator) {
            dispatch(actionCreator());
        }
    };

    const handleActionClick = (action: Action) => () => {
        setCurrentAction(action);
        setShowReadyToSendDialog(true);
        dispatchAction(action);
    };

    // NOTE: Ignoring coverage on the next lines because these are dependent on already asserted code in sub-components tests
    // We ARE asserting coverage on actionable behaviors like: (handleSendToCustomerClick, handleContinueInDeskingClick)

    // istanbul ignore next
    const closeReadyToSendDialog = () => {
        setShowReadyToSendDialog(false);
    };

    const handleContinueToDeskingSaved = () => {
        closeReadyToSendDialog();
        window.open(copyToDeskingURL + dealExchangeDealId, '_blank');
    };

    const handleSendToEcommerceSaved = () => {
        closeReadyToSendDialog();
        const dealExchangeVersion = latestDealExchangeVersion || initDealExchangeDealVersion;
        window.location.assign(`${redirectionLink}/${dealExchangeDealId}/version/${dealExchangeVersion}`);
    };

    const handleReadyToSendSaved = () => {
        switch (currentAction) {
            case Action.CONTINUE_IN_DESKING:
                return handleContinueToDeskingSaved();
            case Action.SEND_TO_ECOMMERCE:
                return handleSendToEcommerceSaved();
        }
    };

    const readyToSendDialogHeader = readyToSendDialogContentMap[currentAction].dialogHeader;
    const readyToSendDialogPrompt = readyToSendDialogContentMap[currentAction].dialogPrompt;

    // istanbul ignore next
    const handleShareButtonClick = (e: any) => {
        setShareDropdown(e.currentTarget);
    };

    // istanbul ignore next
    const handleShareButtonClose = () => setShareDropdown(null);

    // NOTE: Setup menu options; Sales view will always show. Toggling behavior of share to customer and get deal link is
    // based on OCC toggle and CRM Integration settings.
    const options = [
        {
            label: 'Share to Customer',
            value: ShareActionTypes.SHARE_TO_CUSTOMER,
            id: 'share-to-customer-option',
            onSelect: () => {
                handleSaveRequest(ShareActionTypes.SHARE_TO_CUSTOMER);
            }
        },
        {
            label: 'Share to Sales View',
            value: ShareActionTypes.SHARE_TO_SHOW_ROOM,
            id: 'share-to-showroom-option',
            onSelect: () => {
                handleSaveRequest(ShareActionTypes.SHARE_TO_SHOW_ROOM);
            }
        },
        {
            label: 'Get Deal Link',
            value: ShareActionTypes.GET_DEAL_LINK,
            id: 'get-deal-link-option',
            divider: isPdfPrintMvEnabled,
            onSelect: () => {
                handleSaveRequest(ShareActionTypes.GET_DEAL_LINK);
            }
        },
        ...(isPdfPrintMvEnabled
            ? [
                  {
                      label: 'Print',
                      value: ShareActionTypes.PRINT,
                      id: 'print-option',
                      onSelect: () => {
                          handleSaveRequest(ShareActionTypes.PRINT);
                      }
                  }
              ]
            : [])
    ];

    const confirmAndSaveRequest = () => {
        if (isLatestPushToDmsPlusEnabled) {
            setShowReadyToSendDialogSave(true);
        } else {
            handleSaveRequest(ShareActionTypes.UPDATE);
        }
    };

    const handleSave = () => {
        setShowReadyToSendDialogSave(false);
    };

    const handleSaveRequest = (actionType: ShareActionTypes) => {
        switch (actionType) {
            case ShareActionTypes.SHARE_TO_CUSTOMER:
                dispatch(offerActionCreators.saveOffer());
                handleShareButtonClose();
                if (crmIntegrationToggle) {
                    showCustomerCard(true, { tab: 'inbox', messageContentSelector: urlSelector, waitForOfferSave: true });
                } else {
                    setShowShareToCustomerDialog(true);
                }
                break;
            case ShareActionTypes.GET_DEAL_LINK:
                dispatch(offerActionCreators.saveOffer());
                setShowGetDealLinkDialog(true);
                handleShareButtonClose();
                break;
            case ShareActionTypes.SHARE_TO_SHOW_ROOM:
                setShowShareToSalesViewDialog(true);
                handleShareButtonClose();
                break;
            case ShareActionTypes.UPDATE:
                dispatch(offerActionCreators.saveOffer());
                setShowUpdateOffer(true);
                break;
            case ShareActionTypes.PRINT:
                dispatch(printPdfActionCreator.printPdf());
                handleShareButtonClose();
                break;
        }
    };

    useEffect(() => {
        // istanbul ignore next
        if (!showUpdateOffer) return;

        // istanbul ignore next
        if (offerIsBeingSaved === false && offerHasBeenSaved === true) {
            setSuccessNotification(true);
            setShowUpdateOffer(false);
            return;
        }
    }, [offerIsBeingSaved, offerHasBeenSaved]); // eslint-disable-line react-hooks/exhaustive-deps

    // istanbul ignore next
    return (
        <StyledDeskingActions className="text-center" data-testid="desking-actions-container">
            <div className="desking-btn-container">
                {!isContinueToDeskingDisabled && (
                    <Button
                        data-testid="action-copy-to-desking"
                        className="continue-desking-button"
                        size="medium"
                        buttonStyle="tertiary"
                        disabled={!actionsEnabled}
                        onClick={handleActionClick(Action.CONTINUE_IN_DESKING)}
                    >
                        Continue in Desking
                    </Button>
                )}
                {isEcommerceDeal && (
                    <Button
                        data-testid="action-send-to-ecommerce"
                        size="medium"
                        disabled={!actionsEnabled}
                        buttonStyle="tertiary"
                        onClick={handleActionClick(Action.SEND_TO_ECOMMERCE)}
                    >
                        Send to Ecommerce
                    </Button>
                )}
            </div>
            <div className="share-container-buttons">
                <Button
                    endIcon={<ChevronDownIcon />}
                    buttonStyle="secondary"
                    disabled={!actionsEnabled}
                    className="share-dropdown-button"
                    data-testid="share-dropdown"
                    onClick={handleShareButtonClick}
                >
                    Share
                </Button>
                <Menu
                    referenceProps={{
                        open: openShareDropdown,
                        anchorEl: showShareDropdown as MenuReferenceType,
                        onClose: handleShareButtonClose
                    }}
                    position={'top-end'}
                    menuOptions={options.map((item) => ({
                        ...item
                    }))}
                    width={largerThanSM ? '185px' : '100%'}
                />
                <Button data-testid="update-offer-button" size="medium" disabled={!actionsEnabled} onClick={confirmAndSaveRequest}>
                    Update
                </Button>
            </div>

            <ShareToSalesViewDialog
                show={showShareToSalesViewDialog}
                onHide={() => setShowShareToSalesViewDialog(false)}
                onOfferSaveHide={() => {
                    setSuccessNotification(true);
                    setShowShareToSalesViewDialog(false);
                }}
            />
            <ShareToCustomerDialogInterstate
                prefix="share-to-customer"
                show={showShareToCustomerDialog}
                onHide={() => setShowShareToCustomerDialog(false)}
                header="Share To Customer"
                dialogPrompt="Choose how you would like to send this offer to the shopper"
            />
            <ShareToCustomerDialogInterstate
                prefix="get-deal-link"
                show={showGetDealLinkDialog}
                onHide={() => setShowGetDealLinkDialog(false)}
                header="Get Deal Link"
            />
            <UpdateOfferDialogInterstate show={showUpdateOffer} onHide={() => setShowUpdateOffer(false)} />
            <ReadyToSendDialogInterstate
                data-testid="ready-to-send-dialog"
                dialogHeader={readyToSendDialogHeader}
                dialogPrompt={readyToSendDialogPrompt}
                show={showReadyToSendDialog}
                onHide={closeReadyToSendDialog}
                onNo={closeReadyToSendDialog}
                onSaved={handleReadyToSendSaved}
                promptSx={{ padding: '0px' }}
                action={currentAction}
            />
            <Snackbar
                data-testid="update-offer-dialog-success"
                show={successNotification}
                message="We've updated your changes to this offer."
                position="bottom-center"
                onClose={() => setSuccessNotification(false)}
                type="success"
            />
            {!showReadyToSendDialog && (
                <ReadyToSendDialogInterstate
                    data-testid="ready-to-send-save-dialog"
                    dialogHeader={'Warning'}
                    dialogPromptHeader={'Important Note:'}
                    dialogPrompt={`Pushing again will overwrite the existing deal in your DMS.`}
                    show={showReadyToSendDialogSave}
                    onHide={() => setShowReadyToSendDialogSave(false)}
                    onNo={() => setShowReadyToSendDialogSave(false)}
                    onSaved={handleSave}
                    promptSx={{
                        paddingLeft: '0',
                        b: {
                            paddingRight: '5px'
                        }
                    }}
                />
            )}
        </StyledDeskingActions>
    );
};
export default DeskingActionsInterstate;
