// externals
import React from 'react';

// libs
import Row from '@cx/ui/Row';

// types
import { CompareColumnDetailProps } from '../types/compareColumnDetailProps';

// constants
import * as constants from '../../constants';

// components
import MSRP from '../../dealSummary/sections/MSRP.interstate';
import SellingPrice from '../../dealSummary/sections/SellingPrice.interstate';
import Addons from '../../dealSummary/sections/Addons.interstate';
import NetTrade from '../../dealSummary/sections/NetTrade.interstate';
import Rebates from '../../dealSummary/sections/Rebates.interstate';
import CashDown from '../../dealSummary/sections/CashDown.interstate';
import TermLength from '../../dealSummary/sections/TermLength.interstate';
import MonthlyPayment from '../../dealSummary/sections/MonthlyPayment.interstate';
import TotalAmountDue from '../../dealSummary/sections/TotalAmountDue.interstate';
import { EmptyField } from '../../dealSummary/sections/EmptyField';
import Discount from '../../dealSummary/sections/Discount.interstate';
import TotalTax from '../../dealSummary/sections/TotalTax.interstate';
import NetSellingPrice from '../../dealSummary/sections/NetSellingPrice.interstate';
import { Grid } from '@interstate-104/components/Grid';
import { Typography } from '@interstate-104/components/Typography';
import { List } from '@interstate/components/List';

export const CompareFinanceColumnDetail = ({ deal, hasDifference, deltaList }: CompareColumnDetailProps) => {
    return (
        <>
            <Grid container flexDirection="column" gap={1}>
                <Typography variant="h4">{constants.PRICE}</Typography>
                <List>
                    <MSRP deal={deal} />
                    <Discount deal={deal} hasDelta={deltaList.isDiscountDelta} />
                    <SellingPrice deal={deal} hasDelta={deltaList.isSellingPriceDelta} />
                </List>
            </Grid>
            <Grid container flexDirection="column" gap={1}>
                <Typography variant="h4">{constants.ADD_ONS}</Typography>
                <List>
                    <Addons deal={deal} hasDelta={deltaList.isAddOnsDelta} />
                    <TotalTax deal={deal} hasDelta={deltaList.isTotalTaxDelta} />
                    <NetSellingPrice deal={deal} hasDelta={deltaList.isNetSellingPriceDelta} />
                </List>
            </Grid>
            <Grid container flexDirection="column" gap={1}>
                <Typography variant="h4">{constants.FINANCE_REDUCTIONS}</Typography>
                <List>
                    <NetTrade deal={deal} hasDelta={deltaList.isNetTradeDelta} />
                    <Rebates deal={deal} hasDelta={deltaList.isRebatesDelta} />
                    <CashDown deal={deal} hasDelta={deltaList.isCashDownDelta} />
                    {hasDifference ? (
                        <EmptyField />
                    ) : (
                        <>{null}</> // TODO: IF WE SIMPLY USE "hasDifference &&" THE APP BREAKS DUE TO AN INTERSTATE BUG
                    )}
                </List>
            </Grid>
            <Grid container flexDirection="column" gap={1}>
                <Typography variant="h4">{constants.FINANCING_TERMS}</Typography>
                <List>
                    {hasDifference ? (
                        <EmptyField />
                    ) : (
                        <>{null}</> // TODO: IF WE SIMPLY USE "hasDifference &&" THE APP BREAKS DUE TO AN INTERSTATE BUG
                    )}
                    <TermLength deal={deal} hasDelta={deltaList.isTermDelta} />
                    <MonthlyPayment deal={deal} hasDelta={deltaList.isMonthlyPaymentDelta} />
                    <TotalAmountDue deal={deal} hasDelta={deltaList.isTotalDueDelta} />
                </List>
            </Grid>
        </>
    );
};
